import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { Packages } from '../components/Packages'
import { Package, PackageFree } from '../components/Package'
import { Layout } from '../components/AppLayout'
import { Typography } from '@material-ui/core'
import { MdDone } from 'react-icons/md'
import { SignUpButton } from '../components/SignUpButton'
import FreePackage from '../Data/FreePackage.json'
import PaidPlans from '../Data/PaidPlans.json'
import { ButtonCompact } from '../components/Button'
import { Switch, SwitchPlans } from '../components/Switch'
import SignUpData from '../Data/SignUp.json'
import { LinkHubspot, setHubspotPageView } from '../components/LinkHubspot'
import { RDHelmet } from '../components/RDHelmet'

const PricingWrapper = styled.section`
  text-align: center;
  .package-card-list {
    flex: 1;
    font-size: 16;
  }
  .package-card {
    flex: 1;
    margin: 0;
  }
  .check-icon {
    color: #34a1d5;
  }
  .package-list {
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-items: space-around;
  }
  .form-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  h2 {
    text-align: center;
    clip: text;
    background-clip: text;
  }
  .tabpanel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .packages-tabs {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 60vw;
  }
  .tabheader {
    font-size: 16px;
    text-transform: uppercase;
  }
  .featurelist {
    width: 100%;
    max-width: 1000px;
    flex: 2;
  }
  .featureitem {
    height: 32px;
    line-height: 32px;
    width: fit-content;
    min-width: 50%;
    justify-content: center;
  }
`

const WaveWrapper = styled.section`
  margin: 0;
  line-height: 0;
  .wave-background {
    width: 100%;
    bottom: 0;
    margin: 0;
    top: auto;
    right: 0px;
    left: 'auto';
    padding: 0;
    overflow: 'hidden';
  }
`

export interface PackagesData {
  icon: React.ReactNode
  label: string
}

const Pricing = () => {
  const [discountOn, setDiscountOn] = useState(true)
  const [paidPlanSelected, setPaidPlanSelected] = useState(false)
  const [moreDetails, setMoreDetails] = useState(false)
  const controls = useAnimation()

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }

  const handleMoreDetailClick = (evt: any) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!moreDetails) {
      controls.start('visible')
    } else {
      controls.start('hidden')
    }

    setHubspotPageView('/pricing#moredetails')

    setMoreDetails(!moreDetails)
  }

  useEffect(() => {
    setMoreDetails(false)
  }, [paidPlanSelected])

  return (
    <Layout>
      <RDHelmet
        subtitle={'Pricing'}
        contentDescription="Transparent pricing tailored to meet your business needs effectively."
        contentKeywords="content='Pricing plans, Affordable solutions, Subscription options, Scalable pricing, Transparent pricing, Cost-effective, Business packages, Flexible rates, Custom pricing, Value-driven pricing'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Transparent pricing tailored to meet your business needs effectively."
        contentOgUrl="https://raindrop.com/Pricing"
        schemaMarkup={schemaMarkup}
      />
      <PricingWrapper>
        <div
          style={{
            marginTop: 100,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <SwitchPlans onChange={setPaidPlanSelected} SwitchOne="FREE PLAN" SwitchTwo="PAID PLANS" />
        </div>

        {!paidPlanSelected && (
          <div className="tabpanel">
            <Typography variant="h2" style={{ fontSize: 32, marginBottom: 0, marginTop: 16, color: '#34a1d5' }}>
              {FreePackage.title}
            </Typography>
            <Typography style={{ fontSize: 18, marginTop: 6, marginBottom: 0, color: '#111' }}>
              {FreePackage.message}
            </Typography>
            <div style={{ marginTop: 32, marginBottom: 50 }}>
              <LinkHubspot to="https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y">
                <span className="sr-only">Jump to Signup</span>
                <SignUpButton label="Get Raindrop for Free" />
              </LinkHubspot>
            </div>
            <Packages id="free" title={FreePackage.title} para={FreePackage.message}>
              {FreePackage.toolgroups.map((tg: any, pindex: number) => {
                return (
                  <PackageFree
                    key={'free-package-' + pindex}
                    free
                    title={tg.name}
                    subtitle={tg.subtitle}
                    className="package-card"
                  >
                    <ul className="package-card-list">
                      {tg.tools.map((tool: string, tindex: number) => {
                        return !moreDetails || tool !== '... more' ? (
                          <li key={'package-item-' + pindex + '-' + tindex} style={{ display: 'flex', fontSize: 16 }}>
                            <div style={{ marginTop: 2, marginRight: 6 }}>
                              <MdDone className="check-icon" />
                            </div>
                            {tool}
                          </li>
                        ) : null
                      })}
                      {tg.expandded.map((tool: string, tindex: number) => {
                        return (
                          <motion.div
                            key={'package-item-expanded-' + pindex + '-' + tindex}
                            animate={controls}
                            initial="hidden"
                            variants={{
                              visible: { opacity: 1, maxHeight: 50 },
                              hidden: { opacity: 0, maxHeight: 0 },
                            }}
                            transition={{ ease: 'easeOut', duration: 0.5, delay: tindex * 0.05 }}
                          >
                            <li
                              key={'package-item-more-' + pindex + '-' + tindex}
                              style={{ display: 'flex', fontSize: 16 }}
                            >
                              <div style={{ marginTop: 2, marginRight: 6 }}>
                                <MdDone className="check-icon" />
                              </div>
                              {tool}
                            </li>
                          </motion.div>
                        )
                      })}
                    </ul>
                    <ButtonCompact
                      cta={moreDetails ? 'See less' : 'See more'}
                      label={'See more'}
                      onClick={(evt: any) => handleMoreDetailClick(evt)}
                    />
                  </PackageFree>
                )
              })}
            </Packages>
          </div>
        )}
        {paidPlanSelected && (
          <div className="tabpanel">
            <Typography
              variant="h2"
              style={{ fontSize: 32, marginBottom: 0, marginTop: 16, letterSpacing: '1px', color: '#34a1d5' }}
            >
              {PaidPlans.title}
            </Typography>
            <Typography style={{ fontSize: 18, marginTop: 6, marginBottom: 32, color: '#111' }}>
              {PaidPlans.message}
            </Typography>
            <Packages id="paidplans" title={PaidPlans.title} para={PaidPlans.message}>
              {PaidPlans.plans.map((p: any, pindex: number) => {
                return (
                  <Package
                    key={'paid-package-' + pindex}
                    title={p.name}
                    raindropiconcount={pindex + 1}
                    subtitle={p.subtitle}
                    discountmonthlyprice={p.discountmonthlyprice}
                    monthlyprice={p.monthlyprice}
                    yearlyprice={p.yearlyprice}
                    discountOn={discountOn}
                    discountyearlyprice={p.discountyearlyprice}
                    className="package-card"
                  >
                    <Typography
                      style={{
                        marginTop: 0,
                        fontSize: 13,
                        marginBottom: 12,
                        paddingLeft: 2,
                        textAlign: 'left',
                      }}
                    >
                      {p.usage}
                    </Typography>
                    {p.monthlyprice && <Switch discountOn={discountOn} onChange={setDiscountOn} />}
                    {!p.monthlyprice && <div style={{ height: 50, marginBottom: 16 }} />}
                    {p.buttonlabel === 'Buy Now' ? (
                      <SignUpButton label={p.buttonlabel} />
                    ) : (
                      <LinkHubspot to="/contactus">
                        <span className="sr-only">Jump to Contact Us</span>
                        <ButtonCompact variant="outlined" color="primary" cta={p.buttonlabel}>
                          {p.buttonlabel}
                        </ButtonCompact>
                      </LinkHubspot>
                    )}
                    <ul className="package-card-list">
                      {p.groups.map((g: any, tindex: number) => {
                        return (
                          <div key={'package-item-' + pindex + '-' + tindex}>
                            <Typography style={{ fontSize: 20, marginBottom: 6, color: '#34a1d5', fontWeight: 800 }}>
                              {g.message}
                            </Typography>
                            {g.features &&
                              g.features.map((f: string, fi: number) => {
                                return (
                                  <li
                                    key={'group-feature-' + fi}
                                    style={{ display: 'flex', fontSize: 16, margin: 0, padding: 0 }}
                                  >
                                    <div style={{ marginTop: 2, marginRight: 6 }}>
                                      <MdDone className="check-icon" />
                                    </div>
                                    {f}
                                  </li>
                                )
                              })}
                          </div>
                        )
                      })}
                    </ul>
                  </Package>
                )
              })}
            </Packages>
          </div>
        )}
      </PricingWrapper>
      <WaveWrapper>
        <img className="wave-background" src={SignUpData.ImagePath} />
      </WaveWrapper>
    </Layout>
  )
}

export default Pricing
